import { Grid } from '@material-ui/core';
import React from 'react';
import eloLogo from '../../assets/images/educational/elo/elo_logo.svg';
import promotersLogos from '../../assets/images/educational/elo/elo_promoters_logos.webp';
import { useGtXs } from '../../hooks/useGtXs';
import BlackButton from '../BlackButton/BlackButton';
import EloActivity from '../EloActivity/EloActivity';
import Show from '../Show/Show';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { eloActivities } from './eloActivities';
import styles from './EloView.module.scss';
import getVideoOpts from './helpers/getVideoOpts';

export default function EloView() {

    const gtXs = useGtXs()

    const video1Url = "https://www.youtube.com/watch?v=AHRNF2W6cGw"
    const video2Url = "https://www.youtube.com/watch?v=vgxm6nwn20s"
    const videoOpts = getVideoOpts(gtXs)

    return (
        <div>
            <div className={styles.SectionBanner} />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>

                    <div className={styles.Logo}>
                        <img src={eloLogo} alt="logo do projeto elo" />
                    </div>
                    <Grid container justify="space-between" className={styles.Presentation}>
                        <Grid item xs={12} sm={5}>
                            <p>
                                Outubro marca o início do Projeto ELO, dentro e fora do Coliseu. Trata-se de um
                                projeto desenvolvido pelo Serviço Educativo Coliseu, com o apoio da Irmandade
                                dos Clérigos, que promete abrir a possibilidade da experimentação artística a
                                vários contextos da cidade do Porto. Recorrendo a diferentes artes, entre as quais a Música, a Dança e o Teatro, este
                                programa prevê aulas semanais, dentro e fora do Coliseu Porto Ageas, visitas
                                orientadas, ora ao Coliseu, ora à Torre dos Clérigos, e um largo trabalho de
                                cruzamento artístico com base no património imaterial recolhido entre conversas
                                com pessoas da cidade.</p>
                            <p>
                                Com início marcado para outubro de 2023, as várias áreas e grupos de trabalho
                                começam a convergir numa cocriação que será apresentada em junho de 2024.
                                Com o lançamento do programa ao público a 9 de outubro, estão abertas as
                                inscrições até ao dia 20 de outubro para aulas e criação nas duas áreas artísticas
                                cujos grupos de trabalho contam com a participação do público geral:
                            </p>
                        </Grid>
                        <Show on={gtXs}>
                            <Grid container item xs={12} sm={7} justify="flex-end" alignContent="space-between">
                                <VideoPlayer url={video1Url} opts={videoOpts} />
                                <VideoPlayer url={video2Url} opts={videoOpts} />
                            </Grid>
                        </Show>
                    </Grid>

                    <Show on={!gtXs}>
                        <Grid container item justify="space-between">
                            <VideoPlayer url={video1Url} opts={videoOpts} />
                            <VideoPlayer url={video2Url} opts={videoOpts} />
                        </Grid>

                    </Show>

                    <div className={styles.Activities}>

                        <EloActivity activity={eloActivities[0]} disabled={true} />
                        <EloActivity activity={eloActivities[1]} disabled={true} />

                    </div>

                    <div>
                        <div className={styles.CallToAction}>
                            <p>Saiba mais sobre o projeto ELO, aqui:</p>
                            <BlackButton text="ELO" to="/noticia/elo-entre-coliseu-e-clerigos-vai-ouvir-as-historias-do-porto-e-conta-las" />
                        </div>

                        <div className={styles.Promoters}>
                            <img src={promotersLogos} alt="promotores projeto ELO" />
                        </div>

                    </div>

                </main>
            </Grid>
        </div>
    )
}
