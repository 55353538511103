import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userType: UserType;
};


export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  name: Scalars['String'];
  isDefaultUserType: Scalars['Boolean'];
  isAbleToLogin: Scalars['Boolean'];
  isAcceptingUsers: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};


export type UserTypeUsersArgs = {
  filter?: Maybe<UserFilter>;
  sorting?: Maybe<Array<UserSort>>;
};

export type UserFilter = {
  and?: Maybe<Array<UserFilter>>;
  or?: Maybe<Array<UserFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  userType?: Maybe<UserFilterUserTypeFilter>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['ID']>;
  neq?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  like?: Maybe<Scalars['ID']>;
  notLike?: Maybe<Scalars['ID']>;
  iLike?: Maybe<Scalars['ID']>;
  notILike?: Maybe<Scalars['ID']>;
  in?: Maybe<Array<Scalars['ID']>>;
  notIn?: Maybe<Array<Scalars['ID']>>;
};

export type StringFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  notLike?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  notILike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type UserFilterUserTypeFilter = {
  and?: Maybe<Array<UserFilterUserTypeFilter>>;
  or?: Maybe<Array<UserFilterUserTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type UserSort = {
  field: UserSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserSortFields {
  Id = 'id',
  Name = 'name'
}

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<User>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type EventPhoto = {
  __typename?: 'EventPhoto';
  id: Scalars['ID'];
  photoUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  event: Event;
};

export type Promoter = {
  __typename?: 'Promoter';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  events: Array<Event>;
};


export type PromoterEventsArgs = {
  filter?: Maybe<EventFilter>;
  sorting?: Maybe<Array<EventSort>>;
};

export type EventFilter = {
  and?: Maybe<Array<EventFilter>>;
  or?: Maybe<Array<EventFilter>>;
  id?: Maybe<IdFilterComparison>;
  state?: Maybe<StringFieldComparison>;
  startDate?: Maybe<DateFieldComparison>;
  isVisible?: Maybe<BooleanFieldComparison>;
  isVisibleHomeAgenda?: Maybe<BooleanFieldComparison>;
  isArchived?: Maybe<BooleanFieldComparison>;
  category?: Maybe<EventFilterEventCategoryFilter>;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export type EventFilterEventCategoryFilter = {
  and?: Maybe<Array<EventFilterEventCategoryFilter>>;
  or?: Maybe<Array<EventFilterEventCategoryFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type EventSort = {
  field: EventSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum EventSortFields {
  Id = 'id',
  State = 'state',
  StartDate = 'startDate',
  IsVisible = 'isVisible',
  IsVisibleHomeAgenda = 'isVisibleHomeAgenda',
  IsArchived = 'isArchived'
}

export type Room = {
  __typename?: 'Room';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  state: Scalars['String'];
  startDate: Scalars['DateTime'];
  slug: Scalars['String'];
  isVisible: Scalars['Boolean'];
  isVisibleHomeAgenda: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isHighlighted: Scalars['Boolean'];
  isArchivable: Scalars['Boolean'];
  estimatedDuration: Scalars['Float'];
  cover?: Maybe<Scalars['String']>;
  coverWidth?: Maybe<Scalars['Float']>;
  coverHeight?: Maybe<Scalars['Float']>;
  poster?: Maybe<Scalars['String']>;
  homeAgendaImage?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  ticketsSeller: Scalars['String'];
  ticketsUrl: Scalars['String'];
  ticketsInfo: Scalars['String'];
  discountsInfo: Scalars['String'];
  minimumAge: Scalars['String'];
  eventOpening: Scalars['String'];
  roomSheet?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  category: EventCategory;
  room?: Maybe<Room>;
  promoter?: Maybe<Promoter>;
  eventPhotos: Array<EventPhoto>;
};


export type EventEventPhotosArgs = {
  filter?: Maybe<EventPhotoFilter>;
  sorting?: Maybe<Array<EventPhotoSort>>;
};

export type EventPhotoFilter = {
  and?: Maybe<Array<EventPhotoFilter>>;
  or?: Maybe<Array<EventPhotoFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type EventPhotoSort = {
  field: EventPhotoSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum EventPhotoSortFields {
  Id = 'id'
}

export type EventCategory = {
  __typename?: 'EventCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  events: Array<Event>;
};


export type EventCategoryEventsArgs = {
  filter?: Maybe<EventFilter>;
  sorting?: Maybe<Array<EventSort>>;
};

export type EventCategoryConnection = {
  __typename?: 'EventCategoryConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<EventCategory>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type EventCategoryAggregateGroupBy = {
  __typename?: 'EventCategoryAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type EventCategoryCountAggregate = {
  __typename?: 'EventCategoryCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type EventCategorySumAggregate = {
  __typename?: 'EventCategorySumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventCategoryAvgAggregate = {
  __typename?: 'EventCategoryAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventCategoryMinAggregate = {
  __typename?: 'EventCategoryMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type EventCategoryMaxAggregate = {
  __typename?: 'EventCategoryMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type EventPhotoConnection = {
  __typename?: 'EventPhotoConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<EventPhoto>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type EventPhotoAggregateGroupBy = {
  __typename?: 'EventPhotoAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type EventPhotoCountAggregate = {
  __typename?: 'EventPhotoCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type EventPhotoSumAggregate = {
  __typename?: 'EventPhotoSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventPhotoAvgAggregate = {
  __typename?: 'EventPhotoAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventPhotoMinAggregate = {
  __typename?: 'EventPhotoMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type EventPhotoMaxAggregate = {
  __typename?: 'EventPhotoMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Event>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type EventAggregateGroupBy = {
  __typename?: 'EventAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  isVisibleHomeAgenda?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type EventCountAggregate = {
  __typename?: 'EventCountAggregate';
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
  isVisible?: Maybe<Scalars['Int']>;
  isVisibleHomeAgenda?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Int']>;
};

export type EventSumAggregate = {
  __typename?: 'EventSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventAvgAggregate = {
  __typename?: 'EventAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type EventMinAggregate = {
  __typename?: 'EventMinAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EventMaxAggregate = {
  __typename?: 'EventMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type PostPhoto = {
  __typename?: 'PostPhoto';
  id: Scalars['ID'];
  photoUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  post: Post;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description: Scalars['String'];
  type: Scalars['String'];
  state: Scalars['String'];
  cover: Scalars['String'];
  coverWidth?: Maybe<Scalars['Float']>;
  coverHeight?: Maybe<Scalars['Float']>;
  publishedAt: Scalars['DateTime'];
  videoUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  postPhotos: Array<PostPhoto>;
};


export type PostPostPhotosArgs = {
  filter?: Maybe<PostPhotoFilter>;
  sorting?: Maybe<Array<PostPhotoSort>>;
};

export type PostPhotoFilter = {
  and?: Maybe<Array<PostPhotoFilter>>;
  or?: Maybe<Array<PostPhotoFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type PostPhotoSort = {
  field: PostPhotoSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PostPhotoSortFields {
  Id = 'id'
}

export type Highlight = {
  __typename?: 'Highlight';
  id: Scalars['ID'];
  name: Scalars['String'];
  image: Scalars['String'];
  url: Scalars['String'];
  order: Scalars['Float'];
  isHidden: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  endDate?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  event?: Maybe<Event>;
  post?: Maybe<Post>;
};

export type HighlightConnection = {
  __typename?: 'HighlightConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Highlight>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type HighlightAggregateGroupBy = {
  __typename?: 'HighlightAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Float']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type HighlightCountAggregate = {
  __typename?: 'HighlightCountAggregate';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Int']>;
};

export type HighlightSumAggregate = {
  __typename?: 'HighlightSumAggregate';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type HighlightAvgAggregate = {
  __typename?: 'HighlightAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type HighlightMinAggregate = {
  __typename?: 'HighlightMinAggregate';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Float']>;
};

export type HighlightMaxAggregate = {
  __typename?: 'HighlightMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Float']>;
};

export type PostPhotoConnection = {
  __typename?: 'PostPhotoConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<PostPhoto>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PostPhotoAggregateGroupBy = {
  __typename?: 'PostPhotoAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type PostPhotoCountAggregate = {
  __typename?: 'PostPhotoCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type PostPhotoSumAggregate = {
  __typename?: 'PostPhotoSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PostPhotoAvgAggregate = {
  __typename?: 'PostPhotoAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PostPhotoMinAggregate = {
  __typename?: 'PostPhotoMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type PostPhotoMaxAggregate = {
  __typename?: 'PostPhotoMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type PostConnection = {
  __typename?: 'PostConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Post>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PostAggregateGroupBy = {
  __typename?: 'PostAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PostCountAggregate = {
  __typename?: 'PostCountAggregate';
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
};

export type PostSumAggregate = {
  __typename?: 'PostSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PostAvgAggregate = {
  __typename?: 'PostAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PostMinAggregate = {
  __typename?: 'PostMinAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PostMaxAggregate = {
  __typename?: 'PostMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PromoterConnection = {
  __typename?: 'PromoterConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Promoter>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PromoterAggregateGroupBy = {
  __typename?: 'PromoterAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type PromoterCountAggregate = {
  __typename?: 'PromoterCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type PromoterSumAggregate = {
  __typename?: 'PromoterSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PromoterAvgAggregate = {
  __typename?: 'PromoterAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PromoterMinAggregate = {
  __typename?: 'PromoterMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type PromoterMaxAggregate = {
  __typename?: 'PromoterMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type RoomConnection = {
  __typename?: 'RoomConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<Room>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type RoomAggregateGroupBy = {
  __typename?: 'RoomAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type RoomCountAggregate = {
  __typename?: 'RoomCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type RoomSumAggregate = {
  __typename?: 'RoomSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RoomAvgAggregate = {
  __typename?: 'RoomAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type RoomMinAggregate = {
  __typename?: 'RoomMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type RoomMaxAggregate = {
  __typename?: 'RoomMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type ManyResponse = {
  __typename?: 'ManyResponse';
  list: Scalars['Float'];
  success: Scalars['Boolean'];
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Array of nodes. */
  nodes: Array<UserType>;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserTypeAggregateGroupBy = {
  __typename?: 'UserTypeAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeCountAggregate = {
  __typename?: 'UserTypeCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type UserTypeMinAggregate = {
  __typename?: 'UserTypeMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserTypeMaxAggregate = {
  __typename?: 'UserTypeMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type UserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type UserTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isDefaultUserType: Scalars['Boolean'];
  isAbleToLogin: Scalars['Boolean'];
  isAcceptingUsers: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  status: Scalars['String'];
  eventBySlug?: Maybe<Event>;
  me: User;
  user?: Maybe<User>;
  users: UserConnection;
  events: EventConnection;
  postBySlug?: Maybe<Post>;
  posts: PostConnection;
  promoter?: Maybe<Promoter>;
  promoters: PromoterConnection;
  room?: Maybe<Room>;
  rooms: RoomConnection;
  eventCategory?: Maybe<EventCategory>;
  eventCategories: EventCategoryConnection;
  eventPhoto?: Maybe<EventPhoto>;
  eventPhotos: EventPhotoConnection;
  highlight?: Maybe<Highlight>;
  highlights: HighlightConnection;
  postPhoto?: Maybe<PostPhoto>;
  postPhotos: PostPhotoConnection;
  userType?: Maybe<UserType>;
  userTypes: UserTypeConnection;
};


export type QueryEventBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<UserFilter>;
  sorting?: Maybe<Array<UserSort>>;
};


export type QueryEventsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<EventFilter>;
  sorting?: Maybe<Array<EventSort>>;
};


export type QueryPostBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryPostsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PostFilter>;
  sorting?: Maybe<Array<PostSort>>;
};


export type QueryPromoterArgs = {
  id: Scalars['ID'];
};


export type QueryPromotersArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PromoterFilter>;
  sorting?: Maybe<Array<PromoterSort>>;
};


export type QueryRoomArgs = {
  id: Scalars['ID'];
};


export type QueryRoomsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<RoomFilter>;
  sorting?: Maybe<Array<RoomSort>>;
};


export type QueryEventCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryEventCategoriesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<EventCategoryFilter>;
  sorting?: Maybe<Array<EventCategorySort>>;
};


export type QueryEventPhotoArgs = {
  id: Scalars['ID'];
};


export type QueryEventPhotosArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<EventPhotoFilter>;
  sorting?: Maybe<Array<EventPhotoSort>>;
};


export type QueryHighlightArgs = {
  id: Scalars['ID'];
};


export type QueryHighlightsArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<HighlightFilter>;
  sorting?: Maybe<Array<HighlightSort>>;
};


export type QueryPostPhotoArgs = {
  id: Scalars['ID'];
};


export type QueryPostPhotosArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<PostPhotoFilter>;
  sorting?: Maybe<Array<PostPhotoSort>>;
};


export type QueryUserTypeArgs = {
  id: Scalars['ID'];
};


export type QueryUserTypesArgs = {
  paging?: Maybe<OffsetPaging>;
  filter?: Maybe<UserTypeFilter>;
  sorting?: Maybe<Array<UserTypeSort>>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: Maybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: Maybe<Scalars['Int']>;
};

export type PostFilter = {
  and?: Maybe<Array<PostFilter>>;
  or?: Maybe<Array<PostFilter>>;
  id?: Maybe<IdFilterComparison>;
  state?: Maybe<StringFieldComparison>;
  publishedAt?: Maybe<DateFieldComparison>;
  createdAt?: Maybe<DateFieldComparison>;
};

export type PostSort = {
  field: PostSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PostSortFields {
  Id = 'id',
  State = 'state',
  PublishedAt = 'publishedAt',
  CreatedAt = 'createdAt'
}

export type PromoterFilter = {
  and?: Maybe<Array<PromoterFilter>>;
  or?: Maybe<Array<PromoterFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type PromoterSort = {
  field: PromoterSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum PromoterSortFields {
  Id = 'id'
}

export type RoomFilter = {
  and?: Maybe<Array<RoomFilter>>;
  or?: Maybe<Array<RoomFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type RoomSort = {
  field: RoomSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum RoomSortFields {
  Id = 'id'
}

export type EventCategoryFilter = {
  and?: Maybe<Array<EventCategoryFilter>>;
  or?: Maybe<Array<EventCategoryFilter>>;
  id?: Maybe<IdFilterComparison>;
};

export type EventCategorySort = {
  field: EventCategorySortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum EventCategorySortFields {
  Id = 'id'
}

export type HighlightFilter = {
  and?: Maybe<Array<HighlightFilter>>;
  or?: Maybe<Array<HighlightFilter>>;
  id?: Maybe<IdFilterComparison>;
  order?: Maybe<NumberFieldComparison>;
  isActive?: Maybe<BooleanFieldComparison>;
};

export type NumberFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  between?: Maybe<NumberFieldComparisonBetween>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type HighlightSort = {
  field: HighlightSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum HighlightSortFields {
  Id = 'id',
  Order = 'order',
  IsActive = 'isActive'
}

export type UserTypeFilter = {
  and?: Maybe<Array<UserTypeFilter>>;
  or?: Maybe<Array<UserTypeFilter>>;
  id?: Maybe<IdFilterComparison>;
  name?: Maybe<StringFieldComparison>;
};

export type UserTypeSort = {
  field: UserTypeSortFields;
  direction: SortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum UserTypeSortFields {
  Id = 'id',
  Name = 'name'
}

export type Mutation = {
  __typename?: 'Mutation';
  duplicateOneEvent: Event;
  createManySubscriptions: Array<ManyResponse>;
  deleteManySubscriptions: Array<ManyResponse>;
  createOneSubscription: Subscription;
  deleteOneSubscription: Subscription;
  activateOneSubscription: Subscription;
  createOneActivityRegistration: Scalars['Boolean'];
};


export type MutationDuplicateOneEventArgs = {
  input: DuplicateOneEventInput;
};


export type MutationCreateManySubscriptionsArgs = {
  input: CreateManySubscriptionsInput;
};


export type MutationDeleteManySubscriptionsArgs = {
  input: DeleteManySubscriptionsInput;
};


export type MutationCreateOneSubscriptionArgs = {
  input: CreateOneSubscriptionInput;
};


export type MutationDeleteOneSubscriptionArgs = {
  input: DeleteOneSubscriptionInput;
};


export type MutationActivateOneSubscriptionArgs = {
  input: ActivateOneSubscriptionInput;
};


export type MutationCreateOneActivityRegistrationArgs = {
  input: CreateOneActivityRegistrationInput;
};

export type DuplicateOneEventInput = {
  event: DuplicateOneEvent;
};

export type DuplicateOneEvent = {
  id: Scalars['ID'];
};

export type CreateManySubscriptionsInput = {
  subscriptions: CreateManySubscriptions;
};

export type CreateManySubscriptions = {
  name: Scalars['String'];
  email: Scalars['String'];
  lists: Array<Scalars['Int']>;
};

export type DeleteManySubscriptionsInput = {
  subscriptions: DeleteManySubscriptions;
};

export type DeleteManySubscriptions = {
  email: Scalars['String'];
  lists: Array<Scalars['Int']>;
};

export type CreateOneSubscriptionInput = {
  subscription: CreateSubscription;
};

export type CreateSubscription = {
  name: Scalars['String'];
  email: Scalars['String'];
  list: Scalars['Float'];
};

export type DeleteOneSubscriptionInput = {
  subscription: DeleteSubscription;
};

export type DeleteSubscription = {
  email: Scalars['String'];
  list: Scalars['Float'];
};

export type ActivateOneSubscriptionInput = {
  subscription: ActivateSubscription;
};

export type ActivateSubscription = {
  token: Scalars['String'];
  list: Scalars['Float'];
};

export type CreateOneActivityRegistrationInput = {
  activityRegistration: CreateActivityRegistration;
};

export type CreateActivityRegistration = {
  activity: Scalars['String'];
  teacher: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  school: Scalars['String'];
  city: Scalars['String'];
  participants: Scalars['String'];
  educationalLevel: Scalars['String'];
  year: Scalars['String'];
  area?: Maybe<Scalars['String']>;
  preferedDate: Scalars['String'];
  alternativeDate: Scalars['String'];
  confirmation: Scalars['Boolean'];
  subscription: Scalars['Boolean'];
};

export type CreateOneActivityRegistrationMutationVariables = Exact<{
  input: CreateOneActivityRegistrationInput;
}>;


export type CreateOneActivityRegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOneActivityRegistration'>
);

export type EventsQueryVariables = Exact<{
  filter?: Maybe<EventFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<EventSort>>;
}>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventConnection' }
    & { nodes: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'startDate' | 'slug' | 'state' | 'cover' | 'homeAgendaImage' | 'poster' | 'minimumAge'>
      & { room?: Maybe<(
        { __typename?: 'Room' }
        & Pick<Room, 'id' | 'name'>
      )>, category: (
        { __typename?: 'EventCategory' }
        & Pick<EventCategory, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type EventBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type EventBySlugQuery = (
  { __typename?: 'Query' }
  & { eventBySlug?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'cover' | 'coverHeight' | 'coverWidth' | 'description' | 'estimatedDuration' | 'eventOpening' | 'id' | 'name' | 'slug' | 'startDate' | 'state' | 'ticketsInfo' | 'ticketsSeller' | 'ticketsUrl' | 'discountsInfo' | 'videoUrl' | 'minimumAge' | 'roomSheet' | 'isArchived' | 'createdAt' | 'updatedAt'>
    & { promoter?: Maybe<(
      { __typename?: 'Promoter' }
      & Pick<Promoter, 'name' | 'url'>
    )>, room?: Maybe<(
      { __typename?: 'Room' }
      & Pick<Room, 'id' | 'name'>
    )>, eventPhotos: Array<(
      { __typename?: 'EventPhoto' }
      & Pick<EventPhoto, 'id' | 'photoUrl'>
    )> }
  )> }
);

export type HighlightsQueryVariables = Exact<{
  filter?: Maybe<HighlightFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<HighlightSort>>;
}>;


export type HighlightsQuery = (
  { __typename?: 'Query' }
  & { highlights: (
    { __typename?: 'HighlightConnection' }
    & { nodes: Array<(
      { __typename?: 'Highlight' }
      & Pick<Highlight, 'id' | 'name' | 'type' | 'isActive' | 'isHidden' | 'image' | 'url'>
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'slug' | 'startDate'>
      )> }
    )> }
  ) }
);

export type PostsQueryVariables = Exact<{
  filter?: Maybe<PostFilter>;
  paging?: Maybe<OffsetPaging>;
  sorting?: Maybe<Array<PostSort>>;
}>;


export type PostsQuery = (
  { __typename?: 'Query' }
  & { posts: (
    { __typename?: 'PostConnection' }
    & { nodes: Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'name' | 'description' | 'slug' | 'cover' | 'coverWidth' | 'coverHeight' | 'type' | 'publishedAt' | 'videoUrl'>
      & { postPhotos: Array<(
        { __typename?: 'PostPhoto' }
        & Pick<PostPhoto, 'id' | 'photoUrl'>
      )> }
    )> }
  ) }
);

export type PostBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type PostBySlugQuery = (
  { __typename?: 'Query' }
  & { postBySlug?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'name' | 'description' | 'slug' | 'cover' | 'coverWidth' | 'coverHeight' | 'type' | 'publishedAt' | 'videoUrl'>
    & { postPhotos: Array<(
      { __typename?: 'PostPhoto' }
      & Pick<PostPhoto, 'id' | 'photoUrl'>
    )> }
  )> }
);

export type StatusQueryVariables = Exact<{ [key: string]: never; }>;


export type StatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'status'>
);

export type ActivateOneSubscriptionMutationVariables = Exact<{
  input: ActivateOneSubscriptionInput;
}>;


export type ActivateOneSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { activateOneSubscription: (
    { __typename?: 'Subscription' }
    & Pick<Subscription, 'name' | 'email'>
  ) }
);

export type CreateManySubscriptionsMutationVariables = Exact<{
  input: CreateManySubscriptionsInput;
}>;


export type CreateManySubscriptionsMutation = (
  { __typename?: 'Mutation' }
  & { createManySubscriptions: Array<(
    { __typename?: 'ManyResponse' }
    & Pick<ManyResponse, 'list' | 'success'>
  )> }
);

export type DeleteManySubscriptionsMutationVariables = Exact<{
  input: DeleteManySubscriptionsInput;
}>;


export type DeleteManySubscriptionsMutation = (
  { __typename?: 'Mutation' }
  & { deleteManySubscriptions: Array<(
    { __typename?: 'ManyResponse' }
    & Pick<ManyResponse, 'list' | 'success'>
  )> }
);


export const CreateOneActivityRegistrationDocument = gql`
    mutation createOneActivityRegistration($input: CreateOneActivityRegistrationInput!) {
  createOneActivityRegistration(input: $input)
}
    `;

/**
 * __useCreateOneActivityRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateOneActivityRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneActivityRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneActivityRegistrationMutation, { data, loading, error }] = useCreateOneActivityRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneActivityRegistrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOneActivityRegistrationMutation, CreateOneActivityRegistrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOneActivityRegistrationMutation, CreateOneActivityRegistrationMutationVariables>(CreateOneActivityRegistrationDocument, baseOptions);
      }
export type CreateOneActivityRegistrationMutationHookResult = ReturnType<typeof useCreateOneActivityRegistrationMutation>;
export type CreateOneActivityRegistrationMutationResult = ApolloReactCommon.MutationResult<CreateOneActivityRegistrationMutation>;
export type CreateOneActivityRegistrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOneActivityRegistrationMutation, CreateOneActivityRegistrationMutationVariables>;
export const EventsDocument = gql`
    query events($filter: EventFilter, $paging: OffsetPaging, $sorting: [EventSort!]) {
  events(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      startDate
      slug
      state
      cover
      homeAgendaImage
      poster
      minimumAge
      room {
        id
        name
      }
      category {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        return ApolloReactHooks.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
      }
export function useEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, baseOptions);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const EventBySlugDocument = gql`
    query eventBySlug($slug: String!) {
  eventBySlug(slug: $slug) {
    cover
    coverHeight
    coverWidth
    description
    estimatedDuration
    eventOpening
    id
    name
    slug
    startDate
    state
    ticketsInfo
    ticketsSeller
    ticketsUrl
    discountsInfo
    videoUrl
    minimumAge
    roomSheet
    promoter {
      name
      url
    }
    room {
      id
      name
    }
    eventPhotos {
      id
      photoUrl
    }
    isArchived
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useEventBySlugQuery__
 *
 * To run a query within a React component, call `useEventBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventBySlugQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EventBySlugQuery, EventBySlugQueryVariables>) {
        return ApolloReactHooks.useQuery<EventBySlugQuery, EventBySlugQueryVariables>(EventBySlugDocument, baseOptions);
      }
export function useEventBySlugLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EventBySlugQuery, EventBySlugQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EventBySlugQuery, EventBySlugQueryVariables>(EventBySlugDocument, baseOptions);
        }
export type EventBySlugQueryHookResult = ReturnType<typeof useEventBySlugQuery>;
export type EventBySlugLazyQueryHookResult = ReturnType<typeof useEventBySlugLazyQuery>;
export type EventBySlugQueryResult = ApolloReactCommon.QueryResult<EventBySlugQuery, EventBySlugQueryVariables>;
export const HighlightsDocument = gql`
    query highlights($filter: HighlightFilter, $paging: OffsetPaging, $sorting: [HighlightSort!]) {
  highlights(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      type
      isActive
      isHidden
      image
      url
      event {
        id
        name
        slug
        startDate
      }
    }
  }
}
    `;

/**
 * __useHighlightsQuery__
 *
 * To run a query within a React component, call `useHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlightsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useHighlightsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HighlightsQuery, HighlightsQueryVariables>) {
        return ApolloReactHooks.useQuery<HighlightsQuery, HighlightsQueryVariables>(HighlightsDocument, baseOptions);
      }
export function useHighlightsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HighlightsQuery, HighlightsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HighlightsQuery, HighlightsQueryVariables>(HighlightsDocument, baseOptions);
        }
export type HighlightsQueryHookResult = ReturnType<typeof useHighlightsQuery>;
export type HighlightsLazyQueryHookResult = ReturnType<typeof useHighlightsLazyQuery>;
export type HighlightsQueryResult = ApolloReactCommon.QueryResult<HighlightsQuery, HighlightsQueryVariables>;
export const PostsDocument = gql`
    query posts($filter: PostFilter, $paging: OffsetPaging, $sorting: [PostSort!]) {
  posts(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      description
      slug
      cover
      coverWidth
      coverHeight
      type
      publishedAt
      videoUrl
      postPhotos {
        id
        photoUrl
      }
    }
  }
}
    `;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        return ApolloReactHooks.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
      }
export function usePostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = ApolloReactCommon.QueryResult<PostsQuery, PostsQueryVariables>;
export const PostBySlugDocument = gql`
    query postBySlug($slug: String!) {
  postBySlug(slug: $slug) {
    id
    name
    description
    slug
    cover
    coverWidth
    coverHeight
    type
    publishedAt
    videoUrl
    postPhotos {
      id
      photoUrl
    }
  }
}
    `;

/**
 * __usePostBySlugQuery__
 *
 * To run a query within a React component, call `usePostBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePostBySlugQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostBySlugQuery, PostBySlugQueryVariables>) {
        return ApolloReactHooks.useQuery<PostBySlugQuery, PostBySlugQueryVariables>(PostBySlugDocument, baseOptions);
      }
export function usePostBySlugLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostBySlugQuery, PostBySlugQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostBySlugQuery, PostBySlugQueryVariables>(PostBySlugDocument, baseOptions);
        }
export type PostBySlugQueryHookResult = ReturnType<typeof usePostBySlugQuery>;
export type PostBySlugLazyQueryHookResult = ReturnType<typeof usePostBySlugLazyQuery>;
export type PostBySlugQueryResult = ApolloReactCommon.QueryResult<PostBySlugQuery, PostBySlugQueryVariables>;
export const StatusDocument = gql`
    query status {
  status
}
    `;

/**
 * __useStatusQuery__
 *
 * To run a query within a React component, call `useStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatusQuery, StatusQueryVariables>) {
        return ApolloReactHooks.useQuery<StatusQuery, StatusQueryVariables>(StatusDocument, baseOptions);
      }
export function useStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatusQuery, StatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatusQuery, StatusQueryVariables>(StatusDocument, baseOptions);
        }
export type StatusQueryHookResult = ReturnType<typeof useStatusQuery>;
export type StatusLazyQueryHookResult = ReturnType<typeof useStatusLazyQuery>;
export type StatusQueryResult = ApolloReactCommon.QueryResult<StatusQuery, StatusQueryVariables>;
export const ActivateOneSubscriptionDocument = gql`
    mutation activateOneSubscription($input: ActivateOneSubscriptionInput!) {
  activateOneSubscription(input: $input) {
    name
    email
  }
}
    `;

/**
 * __useActivateOneSubscriptionMutation__
 *
 * To run a mutation, you first call `useActivateOneSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateOneSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateOneSubscriptionMutation, { data, loading, error }] = useActivateOneSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateOneSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateOneSubscriptionMutation, ActivateOneSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateOneSubscriptionMutation, ActivateOneSubscriptionMutationVariables>(ActivateOneSubscriptionDocument, baseOptions);
      }
export type ActivateOneSubscriptionMutationHookResult = ReturnType<typeof useActivateOneSubscriptionMutation>;
export type ActivateOneSubscriptionMutationResult = ApolloReactCommon.MutationResult<ActivateOneSubscriptionMutation>;
export type ActivateOneSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateOneSubscriptionMutation, ActivateOneSubscriptionMutationVariables>;
export const CreateManySubscriptionsDocument = gql`
    mutation createManySubscriptions($input: CreateManySubscriptionsInput!) {
  createManySubscriptions(input: $input) {
    list
    success
  }
}
    `;

/**
 * __useCreateManySubscriptionsMutation__
 *
 * To run a mutation, you first call `useCreateManySubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManySubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManySubscriptionsMutation, { data, loading, error }] = useCreateManySubscriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManySubscriptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateManySubscriptionsMutation, CreateManySubscriptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateManySubscriptionsMutation, CreateManySubscriptionsMutationVariables>(CreateManySubscriptionsDocument, baseOptions);
      }
export type CreateManySubscriptionsMutationHookResult = ReturnType<typeof useCreateManySubscriptionsMutation>;
export type CreateManySubscriptionsMutationResult = ApolloReactCommon.MutationResult<CreateManySubscriptionsMutation>;
export type CreateManySubscriptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateManySubscriptionsMutation, CreateManySubscriptionsMutationVariables>;
export const DeleteManySubscriptionsDocument = gql`
    mutation deleteManySubscriptions($input: DeleteManySubscriptionsInput!) {
  deleteManySubscriptions(input: $input) {
    list
    success
  }
}
    `;

/**
 * __useDeleteManySubscriptionsMutation__
 *
 * To run a mutation, you first call `useDeleteManySubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManySubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManySubscriptionsMutation, { data, loading, error }] = useDeleteManySubscriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManySubscriptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteManySubscriptionsMutation, DeleteManySubscriptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteManySubscriptionsMutation, DeleteManySubscriptionsMutationVariables>(DeleteManySubscriptionsDocument, baseOptions);
      }
export type DeleteManySubscriptionsMutationHookResult = ReturnType<typeof useDeleteManySubscriptionsMutation>;
export type DeleteManySubscriptionsMutationResult = ApolloReactCommon.MutationResult<DeleteManySubscriptionsMutation>;
export type DeleteManySubscriptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteManySubscriptionsMutation, DeleteManySubscriptionsMutationVariables>;