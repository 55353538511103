import React from 'react'
import highlight2 from '../../assets/images/home/educational/highlight2.webp'
import highlight1 from '../../assets/images/home/educational/highlight1.webp'
import { useGtXs } from '../../hooks/useGtXs'
import HomeEducationalServiceDesktop from '../HomeEducationalServiceDesktop/HomeEducationalServiceDesktop'
import HomeEducationalServiceMobile from '../HomeEducationalServiceMobile/HomeEducationalServiceMobile'

export interface HomeEducationalContent {
    id: number
    date: string
    name: string
    image: string
    route: string
}

export interface HomeEducationalAction {
    name: string
    route: string
    linkName: string
}


export default function HomeEducationalService() {

    const gtXs = useGtXs()

    const content = [
        {
            id: 1,
            date: "25 out",
            name: "D20 Coliseu",
            image: highlight1,
            route: "/evento/20241025-d20-coliseu-especial-halloween"
        },
        {
            id: 2,
            date: "2024 / 2025",
            name: "Programa Escolas",
            image: highlight2,
            route: "/educativo/programa-escolas"
        },
    ]

    const action = {
        name: "Serviço Educativo Coliseu",
        route: "/educativo",
        linkName: "Serviço Educativo"
    }

    if (gtXs) {
        return <HomeEducationalServiceDesktop content={content} action={action} />
    }
    return <HomeEducationalServiceMobile content={content} action={action} />
}
