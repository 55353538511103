import { setLocale } from 'yup';

export const REQUIRED_FIELD = 'campo obrigatório'

export const Config = {
    // @ts-ignore
    GRAPHQL_API: window._env_ ? window._env_.GRAPHQL_API : '',
    // @ts-ignore
    MEDIA_URL: window._env_ ? window._env_.MEDIA_URL : '',
    // @ts-ignore
    APP: window._env_ ? window._env_.APP : '',
    // @ts-ignore
    MAILJET_GENERAL_LIST_ID: window._env_ ? window._env_.MAILJET_GENERAL_LIST_ID : '',
    // @ts-ignore
    MAILJET_EDUCATIONAL_LIST_ID: window._env_ ? window._env_.MAILJET_EDUCATIONAL_LIST_ID : '',
    // @ts-ignore
    SCHOOLS_PROGRAM: window._env_ ? window._env_.SCHOOLS_PROGRAM : '',
    // @ts-ignore
    SCHOOLS_PROGRAM_2024_2025: window._env_ ? window._env_.SCHOOLS_PROGRAM_2024_2025 : '',
};

setLocale({
    mixed: {
        default: 'Não é válido',
        required: REQUIRED_FIELD
    },
    string: {
        email: "tem que ser indicar um email válido"
    }
});
