import { clsx } from 'clsx';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from 'react-router';
import { useGtXs } from '../../hooks/useGtXs';
import styles from './ActivityCarousel.module.scss';
import { slides } from './slides';

export default function ActivityCarousel() {

    const gtXs = useGtXs()
    const history = useHistory()
    const isBannerLink = false
    const percentage = gtXs ? 34 : 66;

    const handleClick = (slide: any) => {
        if (isBannerLink) {
            history.push(slide.url)
        }
    }

    const classes = clsx({ [styles.Slide]: isBannerLink })

    return (
        <div className={styles.ActivityCarousel}>
            <Carousel showThumbs={false} showArrows={true} centerMode centerSlidePercentage={percentage} swipeable autoPlay infiniteLoop dynamicHeight showStatus={false}>
                {slides.map(slide => (
                    <div key={slide.id} className={classes} onClick={() => handleClick(slide)}>
                        <img src={slide.src} alt={slide.alt} />
                    </div>
                ))}
            </Carousel>
        </div>
    )
}
