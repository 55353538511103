import { Grid } from '@material-ui/core';
import React from 'react';
import mainBanner from '../../assets/images/educational/schools/schools_banner.webp';
import areasDesktop from '../../assets/images/educational/schools/icon_areas_desktop.png';
import areasMobile from '../../assets/images/educational/schools/icon_areas_mobile.png';
import { useGtXs } from '../../hooks/useGtXs';
import { Config } from '../../services/Config';
import ActivityCarousel from '../ActivityCarousel/ActivityCarousel';
import EducationalActivitySearch from '../EducationalActivitySearch/EducationalActivitySearch';
import styles from './SchoolsView.module.scss';

export default function SchoolsView() {

    const gtXs = useGtXs()
    const iconAreas = gtXs ? areasDesktop : areasMobile
    const url = Config.SCHOOLS_PROGRAM_2024_2025
    const isSearchActive = false

    return (
        <div>
            <ActivityCarousel />
            <Grid container justify="center" className={styles.Wrapper}>
                <main>
                    <Grid container spacing={2} className={styles.Introduction}>
                        <Grid item xs={12} sm={7} className={styles.Banner}>
                            <div>
                                <img src={mainBanner} alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5} className={styles.Content}>
                            <Grid>


                                <p>
                                    O Serviço Educativo Coliseu completa um ano! Foram 365 dias repletos de experiências e
                                    aprendizagens com famílias, alunos e professores, que nos ajudaram a crescer e a inventar
                                    novas propostas para diversas idades, do pré-escolar até ao ensino técnico, superior e sénior.
                                    É na partilha que moldamos novas formas de aprender e levar a todos, de forma criativa, a vida mágica e
                                    intensa de um dos teatros mais importantes do país.
                                </p>

                                <p>
                                    Com o novo Programa Escolas 2024/2025, pretendemos continuar a criar laços vincados e duradouros,
                                    partilhar o nosso sentido de missão e proporcionar lugar e tempo para a educação e fruição artísticas,
                                    com atividades e conteúdos transformadores, capazes de inspirar e expandir horizontes.
                                </p>

                                <p><b>SEJA BEM-VINDO AO COLISEU DAS ESCOLAS.</b></p>
                                <p>Consulte o programa completo aqui:</p>

                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    <div className={styles.Download}>
                                        Programa Escolas 2024/25
                                    </div>
                                </a>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={styles.ActivityAreas}>
                        <img src={iconAreas} alt="" />
                    </Grid>

                    {isSearchActive ? <EducationalActivitySearch /> : null}
                </main>
            </Grid>
        </div >
    )
}
