import { Grid } from '@material-ui/core';
import React from 'react';
import Paragraph from '../Paragraph/Paragraph';
import Typography from '../Typography/Typography';
import styles from './SocialEntitiesView.module.scss';

export default function SocialEntitiesView() {
    return (
        <Grid container justify="center" className={styles.Wrapper}>
            <Grid item xs={12} sm={4} className={styles.Image} />
            <Grid item xs={12} sm={8} className={styles.Text}>
                <main>

                    <Typography variant="h1colisseum">
                        Órgãos Sociais
                    </Typography>

                    <section>
                        <Paragraph>
                            Os Órgãos Sociais da Associação Amigos do Coliseu do Porto, entidade gestora do Coliseu Porto Ageas:
                        </Paragraph>

                        <Paragraph>
                            <b>DIREÇÃO</b><br />
                            <b>Miguel Guedes</b> Presidente<br />
                            <b>António Tavares</b><br />
                            <b>Maria João Castro</b><br />
                            <b>Nuno Crespo</b><br />
                            <b>Pedro Carvalho Esteves</b><br />
                        </Paragraph>

                        <Paragraph>
                            <b>ASSEMBLEIA GERAL</b><br />
                            <b>Alberto Amorim Pereira</b> Presidente da Mesa<br />
                            <b>Fernando Pereira de Sousa</b> Vice-presidente<br />
                            <b>Ana Sofia Costa</b> Secretária<br />
                        </Paragraph>

                        <Paragraph>
                            <b>CONSELHO FISCAL</b><br />
                            <b>Gustavo Pimenta</b> Presidente<br />
                            <b>Luís Artur Ribeiro Pereira</b> Vogal<br />
                            <b>Jorge Macedo & Nuno Borges, SROC, Lda,</b> representada por:<br />
                            Jorge Macedo ROC e Nuno Alves Pereira ROC (suplente)<br />
                        </Paragraph>

                    </section>
                </main>
            </Grid>
        </Grid>
    )
}
